import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'wwww',
    redirect: '/portal'
  },
  {
    path: '/portal',
    name: 'portal',
    component: () => import('../views/portal/portal.vue')
  // },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('../views/login/Login.vue')
  // },
  // {
  //   path: '/',
  //   name: 'Layout',
  //   component: () => import('../views/layout/Layout.vue'),
  //   redirect: 'dash',
  //   children: [
  //     {
  //       path: 'dash',
  //       component: () => import('@/views/dash/Dash.vue'),
  //       name: 'Dash',
  //       meta: { title: '首页' }
  //     },
  //     {
  //       path: 'dinner',
  //       component: () => import('@/views/dinner/dinner.vue'),
  //       name: 'Dinner',
  //       meta: { title: '桌台详情' }
  //     },
  //     {
  //       path: 'password',
  //       component: () => import('@/views/profile/password.vue'),
  //       name: 'Password',
  //       meta: { title: '修改密码' }
  //     },
  //     {
  //       path: 'statics',
  //       component: () => import('@/views/statics/statics.vue'),
  //       name: 'Statics',
  //       meta: { title: '统计查询' },
  //     },
  //     {
  //       path: 'order',
  //       component: () => import('@/views/statics/order.vue'),
  //       name: 'order',
  //       meta: {
  //         title: '订单统计',
  //         noCache: true
  //       }
  //     },
  //     {
  //       path: 'dishes',
  //       component: () => import('@/views/meta/dishes.vue'),
  //       name: 'Dishes',
  //       meta: { title: '菜谱数据' }
  //     },
  //     {
  //       path: 'dishesbase',
  //       component: () => import('@/views/meta/dishesbase.vue'),
  //       name: 'DishesBase',
  //       meta: { title: '配套数据' }
  //     },
  //     {
  //       path: 'category',
  //       component: () => import('@/views/meta/category.vue'),
  //       name: 'Category',
  //       meta: { title: '菜谱类属' }
  //     }
  //   ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
